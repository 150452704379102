import { formatListWithAnd } from '@/utilities/intl';
import { TZ } from '@/utilities/timezone';
import { getVehicleSize } from '@components/routing/planning/utils/capacity';
import {
  TZ_EUROPE_LONDON,
  formatIso8601ToTime,
  isDateAfter,
  isInstantAfter,
  isInstantBefore,
  now,
  parseInstant,
  todayLocal,
} from '@packfleet/datetime';
import { Tooltip } from '@packfleet/ui';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import React, { useMemo, useState } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdTrolley } from 'react-icons/md';
import { TbChevronDown, TbChevronUp, TbClockFilled } from 'react-icons/tb';
import {
  DashboardDriverFragment,
  PackStatus,
  useAssignTaskToUserMutation,
  useUnassignTaskFromUserMutation,
} from '../../generated/graphql';
import { extractGraphQLErrorIfExists } from '../../utilities/errors/helpers';
import { getInitials, initializeSurname } from '../../utilities/string';
import Button from '../button/Button';
import Heading from '../heading/Heading';
import PackStatusPill from '../shipment/PackStatusPill';
import { getEmployerName } from '../users/utils';
import { AssignPickerDialog } from './AssignPickerDialog';
import { ProgressBar } from './ProgressBar';
import UnloadingItem from './UnloadingItem';

type Props = {
  d: DashboardDriverFragment;
};

const ROUTE_ENDED_GRACE_PERIOD = 10;

const RouteTimeStat = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="flex items-center flex-col-reverse min-w-[60px]">
    <span className="text-secondary text-xs">{label}</span>
    <span className="font-medium text-md">{children}</span>
  </div>
);

export const DriverRow = ({ d }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [assignTaskToUser] = useAssignTaskToUserMutation();
  const [unassignTaskFromUser] = useUnassignTaskFromUserMutation();

  const fullyLoaded = d.warehousePacksPicked === d.warehousePacksTotal;
  const pickerEditable = !fullyLoaded && !d.routeStartedAt;
  const vehicleSize =
    d.vehicleCapacityKg != null ? getVehicleSize(d.vehicleCapacityKg) : null;

  const picker = d.pickingTask?.assignedToUser
    ? {
        userId: d.pickingTask.assignedToUser.id,
        name: d.pickingTask.assignedToUser.name,
      }
    : undefined;

  const otherPickers = useMemo(
    () => d.pickers.filter((p) => p.id !== picker?.userId),
    [d.pickers, picker?.userId],
  );

  const packsByStatusPairs = useMemo(
    () =>
      Object.entries(
        groupBy(
          d.packsToUnload.filter(
            // Collected packs are listed separately
            (p) => p.status !== PackStatus.Collected,
          ),
          (p) => p.status,
        ),
      ),
    [d.packsToUnload],
  );
  const collectedPacksByOrganization = useMemo(
    () =>
      groupBy(
        d.packsToUnload.filter((p) => p.status === PackStatus.Collected),
        (p) => p.organizationId,
      ),
    [d.packsToUnload],
  );

  const showPackToUnload =
    d.packsToUnload.length || d.packUnloadingByOrganization.length;
  const showPacksToPick = d.packsToPick.length && !d.routeStartedAt;

  const numPacksToCollect = useMemo(
    () =>
      d.packUnloadingByOrganization.reduce(
        (acc, { packsScheduledForCollection, packsCollected }) =>
          acc + (packsScheduledForCollection - packsCollected),
        0,
      ),
    [d.packUnloadingByOrganization],
  );

  const hasCollectedPacksToUnload = d.packUnloadingByOrganization.length > 0;
  const totalPacksToUnload =
    d.packsToUnload.length + d.externalPacksToUnload.length;
  const employerName = getEmployerName(d.driver?.staffMember);

  return (
    <>
      <tr
        key={d.vehicleId}
        className={classNames('border-secondary/10 last-of-type:border-0', {
          'border-b': !expanded,
        })}
      >
        <td className="h-14 pr-3 py-4 pl-4">
          <button
            className="flex h-9 w-9 items-center justify-center rounded-full bg-infoLight/50 text-sm"
            onClick={() => setExpanded(!expanded)}
          >
            {getInitials(d.driver?.name) || '?'}
          </button>
        </td>
        <td className="pr-6 min-w-[240px]">
          <div className="flex gap-2 items-center">
            {d.driver?.name ? (
              <p className={classNames('font-medium', { 'text-lg': expanded })}>
                <button onClick={() => setExpanded(!expanded)}>
                  {d.driver?.name}{' '}
                  {employerName ? (
                    <span className="text-sm">({employerName})</span>
                  ) : null}
                </button>
              </p>
            ) : null}
            <div className="text-success bg-success/20 rounded px-2 text-sm">
              Route {d.routeDisplayName}
            </div>
            {vehicleSize || d.deliveryVehicle ? (
              <div className="text-info bg-info/20 rounded px-2 text-sm">
                {vehicleSize}
                {!vehicleSize
                  ? d.deliveryVehicle?.licensePlate.slice(-3)
                  : d.deliveryVehicle
                    ? ` (${d.deliveryVehicle.licensePlate.slice(-3)})`
                    : null}
              </div>
            ) : null}
          </div>
          {d.startETA &&
          isDateAfter(
            parseInstant(d.startETA)
              .toZonedDateTimeISO(TZ_EUROPE_LONDON)
              .toPlainDate(),
            todayLocal(TZ_EUROPE_LONDON),
          ) ? (
            <div className="uppercase tracking-wider text-xs text-secondary">
              {parseInstant(d.startETA).toLocaleString([], {
                weekday: 'long',
              })}
              &apos;s route
            </div>
          ) : null}
        </td>
        <td className="pr-6 min-w-[140px]">
          <div className="flex items-center gap-2">
            <AssignPickerDialog
              currentPicker={picker}
              otherPickers={otherPickers}
              disabled={!pickerEditable}
              onAssignNewPicker={async (userId) => {
                if (!d.pickingTask) return {};
                if (userId) {
                  return await extractGraphQLErrorIfExists(
                    assignTaskToUser({
                      variables: {
                        input: {
                          taskId: d.pickingTask.id,
                          userId,
                        },
                      },
                    }),
                    'assignTaskToUser',
                  );
                } else if (d.pickingTask.assignedToUser) {
                  return await extractGraphQLErrorIfExists(
                    unassignTaskFromUser({
                      variables: {
                        input: {
                          taskId: d.pickingTask.id,
                          userId: d.pickingTask.assignedToUser.id,
                        },
                      },
                    }),
                    'unassignTaskFromUser',
                  );
                } else {
                  return {};
                }
              }}
            >
              {pickerEditable || picker || otherPickers.length ? (
                <Button
                  s="smaller"
                  disabled={!pickerEditable}
                  color="neutral"
                  mode="outline"
                  icon={MdTrolley}
                  iconClassName={'text-xl'}
                  title={picker?.name ?? 'No picker'}
                  className={classNames(
                    {
                      'opacity-70': !pickerEditable || !picker,
                      'text-warning': !picker && !fullyLoaded,
                    },
                    'grow',
                  )}
                >
                  {picker
                    ? initializeSurname(picker.name)
                    : otherPickers.length === 1
                      ? initializeSurname(otherPickers[0].name)
                      : otherPickers.length
                        ? `${otherPickers.length} pickers`
                        : 'No picker'}
                </Button>
              ) : null}
            </AssignPickerDialog>
            {otherPickers.length && picker ? (
              <Tooltip
                title={`Packs picked by ${formatListWithAnd(
                  otherPickers.map((picker) => initializeSurname(picker.name)),
                )}`}
              >
                <span className="px-2 rounded-full border text-secondary text-sm">
                  +{otherPickers.length}
                </span>
              </Tooltip>
            ) : null}
          </div>
        </td>
        <td className="pr-6 min-w-[180px]">
          <div className="flex justify-start gap-2">
            {!d.shiftStartedAt &&
            d.startETA &&
            d.shiftStartTime &&
            !d.routeStartedAt &&
            // Show the shift not started alert immediately after the shift was supposted to start
            isInstantAfter(now(), parseInstant(d.shiftStartTime)) ? (
              <div className="flex items-center gap-1 rounded bg-danger p-2 text-sm leading-none text-primaryInverted">
                <TbClockFilled /> Shift not started
              </div>
            ) : !d.routeStartedAt &&
              d.startETA &&
              isInstantBefore(parseInstant(d.startETA), now()) ? (
              <div className="flex items-center rounded bg-dangerLight text-danger px-3 py-1.5 text-xs leading-none">
                Late departure
              </div>
            ) : null}
            {d.externalPacksToUnload.length ? (
              <div className="flex items-center rounded bg-warningLight p-2 text-sm text-warning px-3 py-1.5 text-xs leading-none">
                Nationwide packs to unload
              </div>
            ) : null}
          </div>
        </td>
        <td className="pr-6">
          <div className="flex justify-start gap-2">
            {d.finishETA &&
            d.routeStartedAt &&
            !d.routeEndedAt &&
            // Only alert if the route should have finished more than 10 minutes ago
            isInstantAfter(
              now(),
              parseInstant(d.finishETA).add({
                minutes: ROUTE_ENDED_GRACE_PERIOD,
              }),
            ) ? (
              <div className="flex items-center rounded bg-warningLight p-2 text-sm leading-none text-warning">
                Late finish
              </div>
            ) : null}
            {!d.shiftEndedAt &&
            d.routeEndedAt &&
            // Only alert if the route has been ended for more than 10 minutes
            isInstantAfter(
              now(),
              parseInstant(d.routeEndedAt).add({
                minutes: ROUTE_ENDED_GRACE_PERIOD,
              }),
            ) ? (
              <div className="flex items-center rounded bg-warningLight px-3 py-1.5 text-xs leading-none text-warning">
                Shift not ended
              </div>
            ) : null}
            {totalPacksToUnload ? (
              <button
                className="flex items-center rounded bg-info/10 px-3 py-1.5 text-xs leading-none text-info"
                onClick={() => setExpanded(!expanded)}
              >
                {totalPacksToUnload} to unload{' '}
                {d.externalPacksToUnload.length
                  ? `(${d.externalPacksToUnload.length} nationwide)`
                  : null}
              </button>
            ) : null}
            {numPacksToCollect ? (
              <button
                className="flex items-center rounded bg-[#e3e5fa] px-3 py-1.5 text-xs leading-none text-[#5856d6]"
                onClick={() => setExpanded(!expanded)}
              >
                {numPacksToCollect} to collect
              </button>
            ) : null}
          </div>
        </td>
        <td className="pr-2 text-right text-sm relative text-info w-[70px] flex translate-y-[20px]">
          {d.routeStartedAt ? (
            <p className="text-secondary ml-auto opacity-80">
              {d.stopsCompleted} <span className="">of {d.stopsTotal}</span>
            </p>
          ) : d.warehousePacksPicked &&
            d.warehousePacksTotal &&
            d.warehousePacksTotal !== d.warehousePacksPicked ? (
            <div className="ml-auto">
              <p>Picking</p>
              <p className="text-secondary text-xs">
                {d.warehousePacksPicked} of{'  '}
                {d.warehousePacksTotal}
              </p>
            </div>
          ) : d.warehousePacksPicked &&
            d.warehousePacksTotal &&
            d.warehousePacksTotal === d.warehousePacksPicked ? (
            <p className="flex items-center justify-end gap-1.5 translate-x-5">
              Picked <IoCheckmarkCircle size={16} />
            </p>
          ) : null}
        </td>
        <td className="w-full">
          {d.routeStartedAt ? (
            <ProgressBar
              compact={false}
              color="success"
              completed={d.stopsCompleted}
              total={d.stopsTotal}
            />
          ) : d.warehousePacksPicked !== d.warehousePacksTotal ? (
            <ProgressBar
              compact={false}
              color="info"
              completed={d.warehousePacksPicked ?? 0}
              total={d.warehousePacksTotal ?? 0}
            />
          ) : null}
        </td>
        <td className="pl-6 text-right">
          <div className="flex justify-end gap-4">
            {d.startETA && (
              <RouteTimeStat
                label={d.routeStartedAt ? 'STARTED' : 'ROUTE START'}
              >
                {formatIso8601ToTime(d.routeStartedAt ?? d.startETA, TZ)}
              </RouteTimeStat>
            )}
            {!d.shiftEndedAt && d.finishETA && (
              <RouteTimeStat label="ETA">
                {formatIso8601ToTime(d.finishETA, TZ)}
              </RouteTimeStat>
            )}
            {d.routeEndedAt && (
              <RouteTimeStat label="ENDED">
                {formatIso8601ToTime(d.routeEndedAt, TZ)}
              </RouteTimeStat>
            )}
          </div>
        </td>
        <td>
          <button
            className="flex items-center rounded px-4 py-1.5 text-xs leading-none"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <TbChevronUp size={24} /> : <TbChevronDown size={24} />}
          </button>
        </td>
      </tr>
      {expanded ? (
        <tr className="border-y border-secondary/10 last-of-type:border-b-0">
          <td colSpan={9} className="py-4 px-16 w-full">
            {showPackToUnload ? (
              <>
                {hasCollectedPacksToUnload ? (
                  <>
                    <Heading
                      level={4}
                      weight="normal"
                      className="pb-4 uppercase text-xs tracking-wider text-secondary"
                    >
                      Collected Packs to unload
                    </Heading>
                    <div className="pb-6">
                      {d.packUnloadingByOrganization.map(
                        ({
                          organization,
                          packsCollected,
                          packsStowed,
                          packsScheduledForCollection,
                        }) => {
                          const packs =
                            collectedPacksByOrganization[organization.id] ?? [];

                          const listedPacks = packs.slice(0, 10);
                          const remainingPacks = packs.slice(10);

                          return (
                            <div key={organization.id}>
                              <UnloadingItem
                                unloading={{
                                  organizationId: organization.id,
                                  packsScheduledForCollection,
                                  packsCollected,
                                  packsStowed,
                                  organization,
                                }}
                              >
                                {packsStowed > 0 ? (
                                  <span className="text-secondary whitespace-break-spaces text-xs pt-2 flex">
                                    {listedPacks.length ? 'To unload: ' : null}
                                    {listedPacks
                                      .map(
                                        (p) =>
                                          `${p.trackingNumber}${
                                            p.totalPacks !== 1
                                              ? ` (${p.packNumber} of ${p.totalPacks})`
                                              : ''
                                          }`,
                                      )
                                      .join(', ')}
                                    {remainingPacks.length
                                      ? ` and ${remainingPacks.length} more`
                                      : ''}
                                  </span>
                                ) : null}
                              </UnloadingItem>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </>
                ) : null}
                {packsByStatusPairs.length > 0 ? (
                  <div className="pb-6">
                    <Heading
                      level={4}
                      weight="normal"
                      className="pb-4 uppercase text-xs tracking-wider text-secondary"
                    >
                      {hasCollectedPacksToUnload ? 'Other' : ''} packs to unload
                    </Heading>
                    {packsByStatusPairs.map(([status, packs]) => {
                      return (
                        <div key={status} className="pt-2 pb-2">
                          <PackStatusPill
                            status={status as PackStatus}
                            className="mr-2"
                          />
                          <span className="text-secondary whitespace-break-spaces text-sm">
                            {packs
                              .map(
                                (p) =>
                                  `${p.trackingNumber}${
                                    p.totalPacks !== 1
                                      ? ` (${p.packNumber} of ${p.totalPacks})`
                                      : ''
                                  }`,
                              )
                              .join(', ')}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </>
            ) : null}
            {showPacksToPick ? (
              <>
                <Heading
                  level={4}
                  weight="normal"
                  className="pb-4 uppercase text-xs tracking-wider text-secondary"
                >
                  Packs to pick
                </Heading>
                <div className="py-0.5">
                  <span className="text-secondary whitespace-break-spaces text-sm">
                    {d.packsToPick
                      .map(
                        (p) =>
                          `${p.trackingNumber}${
                            p.totalPacks !== 1
                              ? ` (${p.packNumber} of ${p.totalPacks})`
                              : ''
                          }`,
                      )
                      .join(', ')}
                  </span>
                </div>
              </>
            ) : null}
          </td>
        </tr>
      ) : null}
    </>
  );
};
